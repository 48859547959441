import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 881.000000 953.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,953.000000) scale(0.100000,-0.100000)">
          <path d="M4568 9127 c-65 -19 -109 -55 -141 -118 -36 -72 -35 -85 8 -93 19 -4
35 -13 35 -20 0 -8 -18 -31 -39 -53 -93 -92 -71 -242 44 -305 40 -22 63 -28
117 -28 37 0 79 5 94 10 20 8 29 7 38 -4 7 -9 43 -16 106 -20 52 -4 147 -18
210 -31 63 -13 118 -20 122 -17 3 4 20 78 37 164 32 171 46 211 75 216 10 2
35 -10 57 -28 21 -17 39 -27 39 -21 0 5 6 16 13 23 7 7 18 24 24 38 6 14 26
48 42 77 l31 52 -43 30 c-46 31 -100 49 -158 50 -21 0 -70 7 -110 16 -115 24
-116 23 -128 -37 -22 -110 -81 -430 -81 -439 0 -5 -7 -9 -15 -9 -23 0 -28 43
-19 138 5 48 9 133 9 189 0 98 -1 102 -31 140 -20 24 -50 46 -85 60 -70 28
-188 37 -251 20z m176 -31 c56 -7 113 -36 141 -74 18 -24 20 -38 15 -142 -6
-152 -6 -299 0 -330 5 -28 12 -26 -94 -15 -47 5 -59 11 -63 26 -4 14 -11 18
-22 13 -67 -29 -101 -36 -149 -31 -104 11 -162 70 -162 166 0 105 55 153 197
171 129 17 133 19 133 45 0 30 -26 45 -79 45 -32 0 -43 -5 -60 -29 l-21 -29
-67 18 c-37 9 -69 22 -71 28 -6 18 25 67 63 100 35 31 52 38 110 45 30 3 37 3
129 -7z m411 -60 c55 -10 70 -16 69 -29 -2 -21 -3 -21 41 0 33 16 38 16 89 0
81 -26 84 -34 41 -111 -20 -37 -39 -66 -42 -66 -3 0 -16 9 -30 20 -45 35 -81
23 -111 -35 -12 -23 -62 -257 -62 -290 0 -41 -11 -44 -89 -30 -41 8 -76 16
-78 19 -6 5 90 536 96 535 4 0 38 -6 76 -13z"/>
<path d="M4564 9056 c-29 -13 -84 -63 -84 -77 0 -4 19 -11 42 -17 38 -10 44
-9 64 14 19 20 32 24 81 24 36 0 67 -6 80 -15 23 -16 41 -91 26 -106 -5 -5
-58 -16 -119 -25 -60 -9 -124 -23 -143 -32 -50 -23 -73 -66 -68 -126 5 -59 41
-97 107 -117 48 -15 114 -2 160 31 38 27 49 25 56 -9 5 -24 12 -30 46 -35 23
-4 42 -5 43 -4 2 2 6 99 10 216 l7 212 -31 29 c-17 16 -51 34 -75 40 -60 15
-164 13 -202 -3z m201 -293 c-8 -70 -26 -100 -70 -119 -55 -22 -93 -18 -126
15 -45 46 -37 107 18 130 28 11 117 28 162 30 23 1 23 -1 16 -56z"/>
<path d="M4675 8775 c-5 -2 -27 -6 -47 -10 -49 -8 -66 -36 -44 -71 22 -32 57
-39 95 -20 35 19 51 44 51 80 0 21 -5 26 -22 25 -13 0 -27 -2 -33 -4z m18 -51
c-12 -33 -83 -40 -83 -9 0 15 20 22 62 24 21 1 26 -3 21 -15z"/>
<path d="M5097 8988 c-16 -69 -80 -448 -76 -451 2 -3 25 -7 50 -11 l46 -7 17
103 c24 148 45 215 77 250 25 26 35 29 66 26 21 -3 46 -8 55 -12 14 -6 21 0
35 29 17 36 17 37 -2 51 -33 24 -86 17 -127 -16 -30 -25 -38 -27 -47 -16 -7 8
-9 25 -5 40 5 22 3 26 -17 26 -13 0 -34 3 -45 6 -17 4 -23 0 -27 -18z"/>
<path d="M3990 9115 c-19 -9 -45 -26 -57 -37 -22 -21 -24 -21 -74 -5 -63 21
-102 21 -154 1 -22 -9 -57 -17 -77 -19 -21 -2 -76 -9 -123 -16 -70 -10 -85
-15 -85 -30 0 -15 62 -447 75 -522 4 -23 1 -28 -12 -25 -11 2 -41 58 -88 168
-86 200 -96 218 -132 251 -41 39 -66 49 -120 49 -80 0 -121 -17 -177 -73 -50
-50 -52 -52 -102 -48 -56 3 -139 -26 -170 -60 -9 -10 -25 -19 -35 -19 -18 0
-179 -66 -192 -79 -6 -6 212 -539 229 -560 2 -2 50 17 108 41 l105 44 -70 165
c-38 90 -69 176 -69 191 0 39 10 58 31 58 20 0 45 -48 125 -244 27 -65 55
-121 62 -123 16 -7 202 69 202 82 0 6 -29 78 -64 161 -73 171 -83 208 -66 229
35 43 51 19 164 -251 l47 -112 107 46 c59 25 114 45 122 44 8 0 63 7 123 15
87 13 107 19 107 32 0 10 -11 93 -25 186 -26 183 -23 217 22 223 25 4 30 -13
64 -250 21 -146 24 -158 43 -158 31 0 197 28 203 34 3 3 -4 71 -16 150 -29
190 -30 245 -6 262 42 31 46 20 81 -220 l28 -188 27 6 c15 3 66 11 113 18 51
7 86 17 86 23 0 25 -60 402 -69 432 -28 94 -83 137 -185 141 -48 1 -82 -3
-106 -13z m187 -35 c47 -28 62 -58 82 -161 30 -153 55 -329 48 -336 -10 -9
-157 -32 -157 -24 0 30 -62 366 -70 381 -16 31 -73 27 -99 -6 -27 -34 -26 -63
1 -246 12 -81 19 -150 16 -154 -8 -7 -148 -28 -153 -22 -3 2 -16 78 -30 169
-33 208 -33 207 -62 226 -23 15 -27 15 -59 -2 -54 -29 -55 -56 -16 -307 l19
-116 -80 -12 c-43 -6 -81 -10 -83 -8 -3 4 -84 510 -84 527 0 5 19 11 43 15 23
4 56 9 74 12 26 5 32 2 38 -15 6 -21 7 -21 41 8 63 56 171 64 229 19 l27 -21
17 20 c9 12 34 33 55 47 50 34 153 37 203 6z m-968 -200 c22 -11 48 -34 59
-52 35 -57 173 -381 165 -388 -13 -11 -143 -63 -146 -59 -2 2 -36 79 -77 171
-40 92 -81 175 -91 184 -24 21 -64 12 -85 -21 -25 -38 -15 -82 55 -244 33 -78
61 -146 61 -149 0 -7 -134 -66 -146 -64 -4 0 -38 74 -76 164 -71 168 -101 211
-138 201 -27 -7 -60 -50 -60 -79 0 -24 57 -173 104 -274 14 -30 26 -60 26 -67
0 -6 -24 -23 -54 -36 -100 -46 -81 -67 -200 210 -58 137 -106 252 -106 256 0
4 6 7 13 7 6 0 37 12 67 26 52 24 55 24 68 7 13 -17 14 -17 24 2 41 74 147
120 222 95 21 -6 39 -10 40 -8 2 1 15 22 30 46 20 31 42 50 79 68 63 29 115
30 166 4z"/>
<path d="M3990 9042 c-25 -15 -51 -41 -59 -55 -8 -15 -17 -27 -21 -27 -4 0
-21 14 -38 30 -28 26 -39 30 -88 30 -63 0 -123 -27 -149 -67 -20 -30 -53 -30
-57 0 -3 19 -9 22 -41 21 -21 -1 -40 -4 -43 -7 -6 -5 2 -67 37 -287 32 -202
24 -183 76 -177 24 2 46 6 47 8 2 2 -4 58 -15 124 -31 203 -25 259 33 295 70
43 128 14 146 -72 6 -29 18 -100 27 -158 25 -162 19 -150 70 -150 l45 0 0 38
c0 20 -7 73 -15 117 -19 106 -19 198 0 235 32 61 127 77 160 27 15 -23 34
-115 56 -269 7 -48 15 -91 18 -96 3 -4 25 -7 49 -4 51 5 50 -1 21 190 -35 236
-56 273 -157 280 -50 3 -63 0 -102 -26z"/>
<path d="M3072 8854 c-45 -17 -97 -74 -108 -121 -7 -26 -21 -29 -48 -8 -12 9
-42 15 -71 15 -65 0 -124 -39 -151 -99 -20 -47 -45 -53 -62 -16 -11 25 -12 25
-47 8 -19 -9 -35 -21 -35 -27 0 -9 168 -413 177 -425 4 -5 83 21 88 29 2 3
-12 44 -32 91 -74 177 -83 204 -83 247 0 35 6 49 29 72 54 55 113 50 151 -12
11 -18 34 -67 51 -108 48 -118 88 -200 96 -199 5 0 25 8 45 17 l37 17 -59 140
c-63 148 -73 202 -46 254 27 52 107 68 144 29 21 -23 60 -105 109 -228 14 -36
32 -75 40 -87 13 -20 16 -20 53 -5 22 8 40 21 40 28 0 7 -13 41 -29 76 -16 35
-39 87 -50 115 -37 94 -81 172 -105 188 -35 23 -85 26 -134 9z"/>
<path d="M5544 8927 c-30 -15 -64 -42 -84 -68 -19 -24 -38 -47 -43 -50 -4 -3
8 -14 28 -25 46 -24 44 -46 -4 -68 -47 -20 -96 -88 -106 -145 -20 -122 99
-241 241 -241 23 0 47 -6 53 -13 6 -8 35 -22 66 -32 30 -11 55 -22 55 -25 0
-4 -22 -35 -50 -71 -27 -35 -50 -68 -50 -74 0 -15 61 -63 114 -90 53 -26 124
-33 170 -15 44 16 90 76 146 190 30 60 60 110 66 110 9 0 10 -24 6 -85 -6 -99
10 -153 60 -204 108 -111 238 -143 349 -89 29 15 57 24 61 20 3 -4 2 -29 -3
-57 -13 -71 4 -144 48 -203 75 -98 144 -137 243 -137 95 0 162 35 223 117 78
106 92 141 92 238 0 75 -3 90 -27 126 -66 103 -179 164 -276 149 -54 -8 -136
-41 -153 -61 -13 -15 -49 -19 -49 -5 0 5 7 26 16 47 22 53 20 152 -4 206 -41
92 -155 169 -254 171 -99 2 -199 -52 -253 -135 -17 -28 -45 -38 -45 -18 0 6
50 110 111 231 61 121 109 222 107 223 -12 10 -189 96 -196 96 -5 0 -80 -142
-166 -315 -94 -188 -164 -315 -172 -315 -28 0 -15 78 37 218 58 157 65 218 33
272 -43 74 -163 137 -278 146 -51 4 -70 1 -112 -19z m177 -23 c131 -39 199
-99 199 -177 0 -21 -25 -113 -56 -204 -40 -119 -55 -175 -51 -199 3 -18 3 -35
0 -37 -2 -3 -38 7 -79 21 -61 22 -74 31 -74 48 0 20 -4 21 -64 17 -73 -6 -123
7 -167 44 -86 72 -79 198 14 267 31 23 110 27 235 12 l72 -9 0 30 c0 24 -7 32
-35 46 -46 22 -67 21 -98 -3 l-26 -21 -65 37 c-62 36 -64 38 -50 60 15 22 75
67 104 77 29 11 87 7 141 -9z m561 -35 c37 -18 68 -36 68 -42 0 -5 -14 -38
-32 -73 l-32 -62 -70 35 c-39 19 -72 37 -74 38 -4 4 61 135 67 135 2 0 35 -14
73 -31z m-86 -181 c35 -17 64 -37 64 -43 0 -7 -61 -133 -135 -282 -154 -308
-172 -333 -253 -333 -52 0 -136 35 -167 70 -17 18 -16 21 25 72 23 29 45 53
48 53 4 -1 19 -7 34 -14 23 -12 30 -12 44 0 9 8 71 125 137 261 66 137 125
248 130 248 5 0 38 -14 73 -32z m369 -197 c60 -27 103 -68 132 -124 34 -67 29
-155 -12 -237 -43 -86 -36 -85 -138 -21 -131 81 -184 111 -196 111 -17 0 -13
-86 5 -104 38 -39 78 -42 114 -10 13 11 21 8 50 -18 19 -16 48 -41 65 -55 28
-25 29 -26 10 -40 -39 -30 -101 -53 -142 -53 -97 0 -207 71 -255 163 -59 116
38 333 175 392 46 20 144 18 192 -4z m-136 -350 c27 -21 11 -34 -26 -20 -23 8
-29 20 -16 32 10 10 16 8 42 -12z m607 -6 c60 -25 112 -73 140 -130 22 -46 26
-65 22 -113 -5 -65 -35 -134 -82 -187 l-32 -38 -75 64 c-146 124 -171 144
-182 144 -7 0 -16 -12 -21 -27 -13 -37 1 -75 37 -101 25 -19 30 -20 64 -8 l37
14 48 -54 c26 -30 48 -60 48 -67 0 -21 -70 -45 -131 -44 -96 0 -205 75 -245
169 -51 119 36 302 176 371 54 26 143 30 196 7z m-146 -345 l25 -20 -32 0
c-33 0 -49 12 -38 30 9 14 16 12 45 -10z"/>
<path d="M5570 8868 c-56 -29 -61 -49 -16 -70 29 -14 36 -15 52 -3 41 31 138
8 170 -40 14 -21 15 -34 8 -63 -6 -20 -14 -36 -19 -35 -6 1 -67 4 -137 8 -140
8 -170 2 -202 -41 -28 -38 -33 -100 -11 -140 37 -69 141 -101 229 -71 l46 16
0 -35 c0 -35 4 -38 68 -58 19 -6 22 -4 22 21 0 15 21 87 46 158 61 174 69 209
53 246 -16 39 -63 75 -131 98 -95 33 -128 34 -178 9z m104 -255 c76 -6 77 -6
71 -31 -13 -52 -47 -110 -71 -121 -14 -6 -45 -11 -70 -11 -88 0 -136 89 -79
145 26 27 35 28 149 18z"/>
<path d="M5559 8578 c-29 -24 -30 -47 -4 -74 39 -39 111 -27 134 23 18 39 14
53 -16 53 -16 0 -44 3 -63 6 -22 3 -41 0 -51 -8z m101 -37 c0 -33 -82 -41 -88
-8 -3 14 4 17 42 17 25 0 46 -4 46 -9z"/>
<path d="M6205 8825 c-21 -48 -20 -50 25 -70 46 -21 44 -21 64 26 l15 36 -45
21 -45 22 -14 -35z"/>
<path d="M6013 8438 c-66 -134 -127 -249 -137 -257 -11 -9 -30 -12 -57 -8 -32
4 -44 1 -59 -16 -33 -34 -24 -50 47 -81 57 -25 103 -16 141 30 26 31 272 513
272 532 0 4 -17 14 -37 22 -21 8 -41 16 -44 18 -3 1 -60 -107 -126 -240z"/>
<path d="M6383 8461 c-43 -20 -109 -82 -119 -113 -3 -10 -9 -18 -13 -18 -4 0
-16 -27 -26 -59 -22 -75 -15 -135 25 -190 32 -44 115 -97 168 -107 34 -7 118
12 129 28 3 6 -8 24 -25 41 -28 28 -34 29 -57 19 -39 -18 -79 -15 -109 9 -30
24 -56 69 -56 98 0 26 30 101 40 101 4 0 69 -38 145 -85 76 -47 142 -85 145
-85 12 0 50 97 57 145 8 63 -17 125 -72 173 -71 62 -154 78 -232 43z m167 -76
c37 -19 51 -45 52 -96 0 -39 -18 -99 -31 -99 -14 1 -196 119 -199 129 -2 7 13
27 33 46 29 28 45 35 76 35 22 0 53 -7 69 -15z"/>
<path d="M6440 8352 c-13 -10 -20 -23 -17 -29 11 -18 127 -84 137 -78 5 3 10
24 10 45 0 68 -78 104 -130 62z"/>
<path d="M6876 8105 c-54 -19 -105 -62 -148 -126 -70 -103 -60 -207 28 -289
68 -63 137 -84 212 -64 28 8 28 16 -3 52 -22 27 -29 29 -74 24 -47 -4 -53 -2
-86 31 -30 30 -35 42 -35 82 0 45 30 115 49 115 7 0 44 -31 170 -139 91 -78
91 -78 113 -50 69 88 83 190 36 264 -64 101 -159 137 -262 100z m155 -82 c60
-36 72 -110 29 -181 l-20 -33 -90 77 c-50 42 -90 81 -90 86 0 26 70 68 113 68
16 0 42 -8 58 -17z"/>
<path d="M6926 7993 l-30 -16 60 -53 c32 -29 64 -53 70 -54 15 0 24 23 24 58
0 32 -7 43 -40 65 -30 21 -46 21 -84 0z"/>
<path d="M2108 8647 c-48 -34 -87 -66 -87 -72 -1 -5 33 -57 73 -115 171 -241
236 -342 236 -365 0 -28 -43 -73 -79 -82 -58 -15 -68 -4 -297 325 -47 67 -91
122 -97 122 -11 0 -81 -45 -104 -67 -7 -6 -28 -21 -48 -33 -19 -12 -35 -25
-35 -28 0 -4 37 -60 83 -124 45 -64 111 -159 147 -210 123 -175 188 -223 305
-222 53 0 82 6 118 23 78 38 166 110 204 167 30 45 36 65 41 123 7 101 -2 116
-290 525 -35 49 -67 91 -73 92 -5 1 -49 -26 -97 -59z m134 -39 c25 -35 87
-124 140 -198 129 -183 152 -229 152 -305 0 -76 -25 -126 -94 -188 -76 -69
-165 -109 -237 -109 -32 0 -74 7 -93 15 -43 18 -118 97 -185 192 -27 39 -86
123 -132 187 -46 64 -83 118 -83 120 0 3 32 26 70 53 68 46 71 47 85 29 8 -11
47 -66 87 -124 212 -308 238 -331 323 -290 79 38 104 95 70 159 -14 27 -73
115 -211 311 -33 47 -63 90 -67 97 -5 8 16 30 60 62 37 28 69 50 70 51 1 0 21
-28 45 -62z"/>
<path d="M2147 8593 c-20 -14 -37 -30 -37 -35 0 -4 18 -34 41 -65 22 -32 79
-112 126 -178 130 -183 143 -225 92 -296 -31 -44 -113 -82 -161 -76 -56 8 -77
25 -146 123 -34 49 -65 94 -69 99 -5 6 -37 51 -72 100 -35 50 -68 94 -72 99
-8 9 -89 -37 -89 -50 0 -6 52 -81 175 -252 137 -191 174 -222 265 -222 106 0
263 114 293 212 26 84 14 116 -119 308 -32 47 -86 124 -119 172 -33 48 -62 87
-65 88 -3 0 -22 -12 -43 -27z"/>
<path d="M6427 5333 c-4 -1429 -3 -1406 -62 -1522 -58 -115 -177 -147 -252
-68 -71 75 -102 190 -110 419 -6 154 -7 157 -27 152 -12 -3 -196 -40 -411 -83
l-390 -77 -4 -35 c-1 -19 2 -88 9 -154 67 -672 314 -1068 733 -1175 201 -51
470 -53 630 -6 154 46 264 113 378 232 173 182 274 397 324 689 46 273 48 335
52 1668 l5 1267 -436 0 -435 0 -4 -1307z m853 155 c0 -623 -5 -1218 -10 -1323
-33 -625 -130 -911 -398 -1167 -182 -174 -370 -237 -675 -225 -168 7 -263 24
-378 68 -96 37 -155 76 -246 162 -178 169 -288 411 -347 762 -17 100 -36 277
-36 334 l0 38 384 76 c211 43 388 77 393 77 6 0 12 -58 16 -137 10 -223 44
-350 115 -426 48 -51 142 -58 210 -17 69 42 114 163 132 355 6 68 10 575 10
1333 l0 1222 415 0 415 0 0 -1132z"/>
<path d="M6487 5293 c-3 -1264 -3 -1290 -24 -1378 -44 -192 -121 -275 -253
-275 -95 0 -145 36 -201 144 -41 78 -58 163 -66 324 -3 72 -9 132 -12 132 -3
-1 -159 -31 -346 -69 l-340 -68 2 -110 c2 -158 24 -282 84 -485 75 -249 206
-444 375 -557 144 -96 292 -134 539 -135 148 -1 181 2 255 22 257 70 449 245
581 527 65 141 100 291 128 545 22 192 32 798 29 1720 l-3 945 -372 3 -372 2
-4 -1287z"/>
<path d="M1482 5713 l3 -798 33 -120 c39 -146 85 -243 162 -338 214 -265 455
-349 1015 -351 280 -1 358 9 573 76 l32 10 0 819 0 819 270 0 270 0 0 340 0
340 -352 -2 -353 -3 -5 -795 -5 -795 -24 -58 c-44 -112 -147 -209 -254 -243
-69 -22 -242 -30 -320 -14 -173 34 -304 166 -337 339 -6 34 -10 338 -10 812
l0 759 -350 0 -351 0 3 -797z"/>
<path d="M3340 4510 l0 -1270 360 0 360 0 0 729 c0 727 0 729 21 734 11 3 22
0 24 -6 5 -13 92 -344 161 -607 23 -91 58 -223 77 -295 20 -71 56 -211 82
-310 25 -99 51 -195 56 -212 l11 -33 322 0 323 0 37 143 c34 135 43 188 30
174 -3 -3 -23 -70 -43 -149 l-38 -143 -310 -3 -310 -2 -33 127 c-18 71 -63
245 -101 388 -38 143 -81 305 -95 360 -15 55 -37 138 -49 185 -58 213 -155
588 -161 618 -13 67 -24 28 -23 -90 0 -68 0 -453 0 -855 l-1 -733 -340 0 -340
0 2 1250 3 1251 534 0 c293 0 535 -2 536 -3 2 -2 23 -82 48 -178 25 -96 62
-236 82 -310 20 -74 54 -205 76 -290 78 -299 171 -645 175 -649 2 -2 4 -1 4 3
0 4 29 117 64 249 36 133 90 339 121 457 31 118 63 240 72 270 8 30 30 114 49
185 18 72 41 160 51 197 l18 68 535 1 535 1 0 -1001 c0 -663 3 -1000 10 -998
7 3 10 347 10 1008 l0 1004 -552 3 -552 2 -14 -52 c-9 -29 -39 -143 -68 -253
-53 -204 -110 -420 -194 -740 -27 -99 -53 -199 -58 -223 -9 -35 -14 -42 -31
-40 -23 3 -21 -3 -100 303 -53 201 -57 218 -130 490 -31 116 -73 278 -94 360
l-39 150 -556 3 -557 2 0 -1270z"/>
<path d="M3405 5688 c-3 -18 -5 -559 -3 -1201 3 -1025 5 -1169 18 -1177 9 -6
137 -10 297 -10 l283 0 2 933 3 932 24 0 c27 0 17 28 98 -293 21 -84 42 -150
46 -146 4 4 7 -4 7 -18 0 -29 49 -234 84 -353 13 -44 33 -118 45 -165 11 -47
23 -92 26 -100 2 -8 13 -50 23 -93 10 -44 21 -75 26 -70 5 4 7 1 3 -8 -3 -9 3
-48 13 -85 11 -38 24 -89 31 -114 6 -25 24 -90 39 -145 16 -55 36 -133 46
-173 9 -41 24 -80 32 -88 11 -12 60 -14 272 -14 l258 1 15 42 c26 69 46 158
40 175 -4 9 -2 13 4 9 6 -4 14 10 18 36 4 23 10 51 12 62 8 35 6 68 -7 135
-15 77 -16 87 -24 205 -3 50 -9 120 -12 156 -3 37 -2 69 3 72 5 3 33 8 63 12
29 3 57 8 62 11 5 3 23 7 40 9 17 3 31 6 32 7 7 12 51 165 77 268 18 69 49
190 70 270 21 80 53 201 71 270 31 118 34 125 58 125 l25 0 5 -435 5 -435 55
9 c43 7 51 10 35 17 -13 5 -5 6 20 3 23 -3 61 0 85 6 80 22 210 47 210 41 0
-3 2 -69 4 -146 2 -77 9 -167 15 -200 6 -33 13 -77 16 -99 2 -21 8 -43 13 -50
5 -6 13 -22 17 -36 15 -48 55 -90 86 -90 l29 0 0 985 0 985 -493 0 -492 0 -28
-102 c-16 -57 -32 -119 -37 -138 -5 -19 -18 -69 -30 -110 -12 -41 -35 -131
-51 -200 -16 -69 -34 -134 -39 -145 -5 -11 -12 -36 -15 -55 -3 -19 -9 -46 -14
-60 -15 -40 -45 -174 -43 -184 2 -5 -1 -12 -6 -15 -8 -6 -55 -179 -106 -386
-21 -88 -22 -90 -52 -93 -31 -3 -32 -1 -39 45 -4 26 -11 55 -15 63 -4 8 -10
31 -13 50 -3 19 -15 71 -27 115 -21 73 -51 185 -116 430 -13 50 -26 102 -29
116 -3 14 -14 55 -24 90 -11 35 -32 113 -46 174 -15 60 -30 112 -35 115 -5 3
-8 12 -7 20 1 8 -6 47 -16 85 -10 39 -22 86 -27 105 -5 19 -12 45 -15 58 l-6
22 -494 0 -494 0 -6 -32z m1832 -55 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7
2 -21 0 -30z m30 5 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-841 -93 c13 -29 14 -42 6 -68 -10 -30 -10 -29 -5 12 4 32 3 42 -5 37 -7 -4
-12 -1 -12 8 0 8 -3 21 -6 30 -12 31 5 16 22 -19z m773 -120 c-1 -16 -2 -16
-9 5 -6 19 -8 21 -9 6 -1 -11 -4 -17 -7 -13 -3 3 -3 15 1 28 6 17 9 19 16 8 5
-8 8 -23 8 -34z m-38 -66 c-5 -13 -10 -19 -10 -12 -1 15 10 45 15 40 3 -2 0
-15 -5 -28z m8 -63 c-9 -8 -10 -7 -5 7 3 10 7 28 7 40 2 16 3 14 6 -7 3 -16
-1 -34 -8 -40z m-661 -53 c5 -23 5 -40 1 -37 -5 3 -9 13 -10 22 0 16 -1 16 -7
0 -4 -10 -7 12 -7 49 0 43 2 55 6 37 4 -16 11 -48 17 -71z m1062 -48 c0 -8 -2
-15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m98 -2 c-10
-2 -18 -12 -18 -21 0 -13 -2 -14 -9 -4 -5 8 -20 15 -35 15 -48 3 -27 14 27 14
28 1 44 -1 35 -4z m-1601 -9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m463 -15 c0 -6 -8 -18 -17 -27 -16 -16 -16 -16 -10 1 4 10 7 25 7 33 0
9 5 12 10 9 6 -3 10 -11 10 -16z m577 -1 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m436 -13 c-7 -21 -13 -19 -13 6 0 11 4 18 10 14 5 -3 7 -12 3
-20z m132 5 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11
-10z m-8 -638 c-1 -114 -2 -20 -2 208 0 228 1 322 2 207 2 -114 2 -300 0 -415z
m-578 522 c-18 -22 -21 -11 -7 27 11 30 13 31 16 11 2 -13 -2 -30 -9 -38z
m558 14 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-1524 -78 c4
-21 3 -29 -2 -20 -5 8 -11 33 -15 55 -7 48 8 18 17 -35z m14 33 c-3 -10 -5 -4
-5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m1510 -515 c-1 -101 -2 -19 -2 182 0
201 1 283 2 183 2 -101 2 -265 0 -365z m-540 510 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m-535 -44 c-6 -12 -9 -7 -9 16 0 23 3 28 9 16 5 -9
5 -23 0 -32z m-415 -6 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m1307 -21 c4 -19 3 -26 -4 -22 -5 3 -10 18 -10 32 0 32 5 28 14 -10z m26 12
c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z
m-1340 -16 c0 -5 -5 -15 -10 -23 -8 -12 -10 -11 -10 8 0 12 5 22 10 22 6 0 10
-3 10 -7z m427 -15 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m460
-30 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-445 -15 c-5 -24
-6 -25 -12 -8 -6 16 2 33 16 35 1 0 -1 -12 -4 -27z m891 7 c-3 -11 -9 -20 -14
-20 -14 0 -11 28 4 33 6 3 13 6 14 6 0 1 -1 -8 -4 -19z m-1336 -12 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m880 -30 c-3 -8 -6 -5 -6 6 -1 11
2 17 5 13 3 -3 4 -12 1 -19z m-440 -20 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m424 -39 l-10 -24 0 26 c-1 14 2 29 6 33 12 12 14 -9 4 -35z
m448 -9 c0 -10 -3 -8 -9 5 -12 27 -12 43 0 25 5 -8 9 -22 9 -30z m-1249 -81
c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m17
-11 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1 -93 l0 -30 -8 30
c-4 17 -8 41 -8 55 l0 25 8 -25 c4 -14 8 -38 8 -55z m1159 18 c-3 -10 -5 -2
-5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m-698 -53 c1 -8 -6 -2 -14 14 -8 15
-15 36 -14 45 1 19 28 -37 28 -59z m266 33 c-9 -57 -27 -124 -32 -119 -2 2 0
21 6 43 6 21 13 55 16 76 3 20 8 37 11 37 3 0 2 -17 -1 -37z m-718 -65 c-3 -7
-5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m419 -14 c-19 -19 -20 -14 -4
22 10 21 14 24 16 11 2 -9 -4 -24 -12 -33z m723 0 c1 11 4 17 7 13 3 -3 3 -15
-1 -28 -6 -17 -9 -19 -16 -8 -5 8 -8 21 -8 29 0 10 3 8 9 -5 8 -19 9 -19 9 -1z
m-432 -56 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-670 -30 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1743 -20 c0 -4 -4 -8 -9 -8
-6 0 -12 4 -15 8 -3 5 1 9 9 9 8 0 15 -4 15 -9z m-677 -13 c-3 -9 -8 -14 -10
-11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m617 0 c0 -8 -7 -15 -15 -15 -9 0 -12 6
-9 15 4 8 10 15 15 15 5 0 9 -7 9 -15z m-1212 -60 c0 -16 -2 -15 -8 5 l-8 25
-1 -24 c-1 -13 -4 -22 -7 -19 -3 3 -2 23 2 44 7 36 8 37 15 14 4 -14 7 -34 7
-45z m-441 23 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m1773 22
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-1793 -32 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m20 -45 c-3
-10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m990 -15 c-3 -7 -5 -2 -5
12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m30 10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m-449 -53 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10
-15 7 -18z m312 -5 c-19 -12 -30 -12 -30 -1 0 10 31 20 40 14 2 -2 -2 -8 -10
-13z m103 13 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-517 -40
c-7 -7 -26 7 -26 19 0 6 6 6 15 -2 9 -7 13 -15 11 -17z m101 1 c2 -7 -8 -9
-28 -6 -22 3 -27 7 -18 13 17 11 41 7 46 -7z m270 10 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m-406 -31 c13 -16 12 -17 -3 -4 -17 13 -22 21
-14 21 2 0 10 -8 17 -17z m66 -9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m-451 -48 c3 -14 4 -33 1 -43 -3 -11 -5 -6 -6 13 0 16 -6 35 -13 42
-9 9 -9 12 0 12 6 0 14 -11 18 -24z m31 -23 c-3 -10 -5 -4 -5 12 0 17 2 24 5
18 2 -7 2 -21 0 -30z m1690 -80 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2
-21 0 -30z m-1697 -28 c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5
-8 10 -19 10 -25z m1772 -119 c2 -19 0 -32 -4 -30 -8 5 -12 64 -4 64 2 0 6
-15 8 -34z m-32 -1 c0 -8 -2 -15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0
10 -7 10 -15z m30 -62 c0 -20 -16 -8 -23 17 -4 17 -3 19 9 9 8 -6 14 -18 14
-26z m-1686 -4 c-3 -6 -6 -32 -6 -58 l0 -46 -13 35 c-15 39 -19 78 -5 45 8
-18 9 -17 9 8 1 15 6 27 12 27 5 0 7 -5 3 -11z m674 -43 c-7 -8 -15 -12 -17
-11 -5 6 10 25 20 25 5 0 4 -6 -3 -14z m-661 -28 c-3 -7 -5 -2 -5 12 0 14 2
19 5 13 2 -7 2 -19 0 -25z m663 -58 l-11 -25 6 25 c2 14 5 34 6 45 2 19 2 19
6 0 2 -12 -1 -32 -7 -45z m-663 -27 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7
2 -21 0 -30z m670 -50 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z
m-611 -149 c-6 -17 -26 -10 -26 9 0 7 4 6 10 -3 7 -11 10 -5 11 25 2 32 3 34
6 12 3 -16 2 -35 -1 -43z m538 -54 c0 -27 -3 -53 -7 -57 -9 -8 -9 69 -1 91 3
9 7 16 8 16 0 0 1 -22 0 -50z m-524 16 c0 -8 -4 -18 -10 -21 -5 -3 -10 3 -10
14 0 12 5 21 10 21 6 0 10 -6 10 -14z m497 -38 c-3 -8 -6 -5 -6 6 -1 11 2 17
5 13 3 -3 4 -12 1 -19z m-494 -28 c-3 -12 -8 -19 -11 -16 -5 6 5 36 12 36 2 0
2 -9 -1 -20z m-695 -27 c-75 -2 -201 -2 -280 0 -79 1 -17 3 137 3 154 0 218
-2 143 -3z m1080 0 c-71 -2 -185 -2 -255 0 -71 1 -13 3 127 3 140 0 198 -2
128 -3z"/>
<path d="M5515 4753 c-87 -326 -133 -507 -129 -512 11 -12 25 31 78 239 31
123 59 226 60 228 2 1 11 0 20 -4 14 -5 16 -30 16 -214 0 -129 4 -211 10 -215
7 -4 10 113 10 344 0 193 -1 351 -3 351 -2 0 -29 -98 -62 -217z"/>
<path d="M2835 2571 c-73 -34 -105 -88 -105 -178 l0 -43 -42 0 c-40 0 -44 3
-63 41 -30 64 -91 125 -153 156 -56 27 -61 28 -263 31 -243 4 -277 -2 -314
-56 -36 -52 -34 -102 6 -147 l31 -35 -3 -163 -4 -164 -32 -37 c-38 -42 -44
-96 -18 -146 29 -56 53 -61 293 -61 217 -1 232 1 311 38 21 11 38 13 46 7 50
-37 68 -45 122 -50 86 -9 166 3 208 31 l35 24 35 -24 c65 -43 311 -49 376 -8
l31 20 52 -20 c73 -30 281 -31 344 -3 41 19 45 19 73 2 34 -20 146 -32 212
-22 25 4 62 18 82 32 l36 24 28 -21 c37 -27 94 -38 207 -39 170 0 244 43 244
142 0 41 -5 54 -28 77 -15 15 -32 30 -37 32 -6 2 -35 63 -64 134 -127 305
-196 410 -287 435 -94 27 -174 -17 -216 -117 -12 -29 -25 -53 -29 -53 -4 0
-16 20 -27 43 -28 60 -70 107 -110 124 -27 11 -44 12 -94 2 -41 -8 -114 -10
-229 -6 l-169 7 -34 -31 c-43 -38 -81 -102 -97 -162 -6 -26 -15 -49 -19 -52
-4 -2 -19 20 -33 49 -30 61 -90 143 -121 166 -56 41 -121 49 -181 21z m-403
-47 c62 -20 127 -80 161 -145 52 -104 57 -268 12 -376 -14 -33 -7 -44 13 -20
6 6 40 85 76 173 52 128 68 179 75 243 12 111 50 151 144 151 38 0 51 -6 92
-42 38 -34 59 -67 115 -182 37 -78 85 -185 106 -240 27 -69 46 -103 64 -114
14 -9 28 -20 31 -25 4 -5 15 -2 25 6 67 55 62 41 69 191 l7 139 -32 -26 c-59
-50 -118 -48 -150 6 -38 61 -16 165 48 237 l37 41 242 2 c226 1 244 -1 264
-19 27 -25 55 -79 70 -138 32 -127 -71 -212 -164 -135 l-37 30 0 -134 0 -135
43 -44 c38 -39 44 -42 58 -28 8 8 28 24 42 35 43 32 156 320 166 425 6 56 14
87 27 101 71 78 184 60 252 -39 37 -56 103 -193 171 -358 38 -93 55 -122 76
-132 56 -29 57 -105 2 -146 -26 -19 -42 -21 -179 -21 l-150 0 -30 34 c-30 33
-33 58 -13 104 6 15 1 17 -40 17 -43 0 -45 -1 -30 -16 11 -11 15 -30 13 -57
-5 -62 -37 -81 -149 -86 -104 -4 -143 8 -169 52 -17 27 -18 28 -24 9 -4 -12
-26 -30 -50 -41 -35 -18 -61 -21 -157 -21 -118 0 -176 15 -208 53 -12 15 -15
15 -33 -8 -26 -34 -75 -45 -198 -45 -123 0 -172 11 -194 45 -18 27 -21 80 -6
90 22 13 8 25 -30 25 -38 0 -52 -12 -30 -25 13 -8 13 -61 0 -86 -19 -35 -64
-49 -157 -49 -99 0 -130 12 -153 55 -8 17 -16 32 -18 34 -1 3 -12 -5 -25 -17
-12 -11 -45 -31 -72 -44 -46 -21 -65 -23 -250 -24 -110 -1 -214 3 -232 8 -21
6 -36 20 -47 43 -21 43 -13 78 24 108 l29 22 7 186 7 186 -32 28 c-33 30 -42
77 -21 114 16 30 38 35 180 42 169 8 272 3 332 -17z m1478 -329 c0 -3 -17 -46
-37 -96 -42 -102 -64 -122 -108 -99 -24 13 -25 18 -25 103 l0 90 33 -6 c19 -3
49 -2 68 3 42 10 69 12 69 5z m-530 -36 c0 -14 -3 -53 -6 -86 -6 -53 -9 -60
-36 -72 -16 -7 -31 -12 -32 -10 -2 2 -22 48 -44 101 l-40 96 79 -1 c78 -2 79
-2 79 -28z"/>
<path d="M2866 2448 c-7 -13 -16 -50 -19 -83 -3 -33 -8 -68 -13 -79 -6 -16 -4
-17 14 -7 55 29 89 5 133 -93 22 -50 18 -91 -11 -106 -29 -16 -133 -12 -157 5
-12 8 -23 28 -25 43 -2 21 -11 6 -43 -72 -23 -57 -54 -114 -72 -134 -18 -19
-33 -36 -33 -38 0 -2 29 -4 65 -4 66 0 78 7 50 30 -24 20 -19 77 10 106 25 25
30 26 131 22 97 -3 107 -5 125 -27 23 -28 25 -69 4 -98 -20 -29 -6 -33 98 -32
93 0 131 10 105 25 -18 11 -46 64 -88 169 -36 92 -153 333 -172 356 -16 20
-52 39 -72 39 -9 0 -22 -10 -30 -22z"/>
<path d="M3725 2458 c-22 -5 -113 -8 -203 -6 -140 4 -164 2 -177 -12 -15 -17
-45 -93 -45 -116 0 -24 29 -15 47 14 49 79 94 94 133 45 21 -27 22 -34 18
-208 -5 -195 -17 -247 -58 -260 -17 -5 -19 -10 -11 -18 14 -14 244 -17 257 -4
4 4 -8 20 -27 36 l-34 29 -3 199 c-3 190 -2 200 18 226 14 17 31 27 48 27 35
0 44 -7 76 -57 14 -24 33 -43 41 -43 31 0 10 118 -26 146 -9 7 -27 8 -54 2z"/>
<path d="M4115 2456 c-13 -9 -21 -32 -26 -77 -3 -35 -10 -77 -14 -94 -8 -28
-8 -29 9 -12 28 27 71 22 95 -12 27 -38 60 -111 61 -133 0 -35 -40 -58 -99
-58 -71 0 -104 17 -111 55 -6 34 -12 28 -30 -30 -23 -71 -72 -165 -96 -184
-13 -11 -24 -22 -24 -25 0 -3 29 -6 65 -6 66 0 78 7 50 30 -24 20 -19 77 10
106 25 25 30 26 129 22 85 -2 108 -6 125 -22 25 -22 28 -72 6 -102 -8 -12 -11
-24 -8 -28 9 -8 184 -6 206 3 18 6 18 8 2 21 -21 18 -42 59 -100 200 -74 181
-135 298 -172 331 -38 33 -51 35 -78 15z"/>
<path d="M2000 2454 c0 -3 10 -14 23 -24 22 -18 22 -22 22 -237 0 -231 -8
-276 -47 -289 -11 -3 -17 -8 -15 -11 3 -2 88 -4 191 -4 208 0 252 10 308 73
64 71 87 220 53 337 -20 66 -84 134 -140 150 -41 11 -395 16 -395 5z m320 -29
c63 -32 100 -132 100 -265 -1 -130 -30 -208 -91 -244 -38 -22 -111 -21 -140 3
-24 19 -24 22 -27 243 -3 216 -2 225 18 251 18 22 29 27 66 27 25 0 58 -7 74
-15z"/>
<path d="M2250 2358 c-6 -85 1 -373 9 -376 48 -16 87 89 77 204 -8 89 -22 133
-48 157 -17 15 -38 23 -38 15z"/>
<path d="M4974 2580 c-71 -10 -121 -37 -180 -95 -78 -78 -102 -172 -69 -280
13 -44 13 -50 -4 -74 -26 -37 -31 -77 -20 -146 15 -90 54 -154 111 -182 64
-32 146 -46 243 -41 99 4 175 37 238 102 l44 45 39 -40 c21 -22 68 -54 103
-72 56 -27 77 -32 162 -35 114 -5 184 12 263 65 51 35 51 35 77 -9 24 -43 59
-49 285 -49 247 -1 287 8 353 71 101 98 119 232 46 340 -33 50 -34 51 -19 91
20 58 18 122 -7 173 -24 52 -49 76 -109 107 -43 23 -51 23 -265 20 -121 -1
-236 -2 -255 0 -19 2 -81 3 -137 4 -90 0 -105 -3 -132 -22 -17 -13 -35 -23
-40 -23 -5 0 -27 10 -48 23 -34 20 -51 22 -163 22 -103 0 -133 -4 -168 -19
-41 -19 -45 -19 -72 -2 -17 9 -46 16 -67 15 -33 -2 -82 4 -143 15 -8 2 -38 0
-66 -4z m189 -46 c57 -6 72 -11 94 -35 27 -28 27 -28 41 -7 25 36 74 48 189
48 122 0 169 -15 191 -63 17 -35 3 -83 -31 -109 -20 -15 -22 -27 -25 -135 -5
-143 7 -189 49 -207 40 -17 75 -9 99 22 18 22 20 40 20 167 0 129 -2 144 -20
160 -19 18 -20 16 -22 -151 l-3 -169 -33 -3 c-44 -4 -52 21 -52 164 l0 114 40
37 c29 25 38 39 30 47 -19 19 -11 71 16 97 24 23 32 24 132 22 59 -1 123 -1
142 -1 456 4 464 3 504 -16 58 -29 96 -95 96 -166 0 -36 -7 -70 -20 -94 l-19
-38 31 -31 c102 -103 65 -293 -70 -358 -44 -21 -60 -23 -262 -24 -242 -2 -261
2 -280 59 -9 28 -8 40 4 64 9 17 21 32 27 34 15 5 28 130 29 280 0 114 -2 128
-19 137 -13 7 -23 7 -34 -2 -13 -10 -16 -45 -20 -197 -4 -196 -10 -224 -59
-281 -38 -44 -106 -80 -180 -95 -185 -39 -363 47 -400 193 l-11 46 -14 -54
c-15 -60 -71 -127 -134 -159 -114 -59 -351 -36 -407 40 -25 33 -52 127 -52
177 0 38 25 83 46 83 18 0 18 10 -3 51 -25 50 -29 150 -8 204 21 56 103 130
165 150 44 14 70 14 233 -1z"/>
<path d="M4972 2458 c-29 -6 -58 -23 -87 -51 -106 -102 -56 -236 114 -302 40
-16 81 -40 92 -53 27 -35 25 -83 -7 -113 -22 -20 -35 -24 -87 -23 -75 0 -120
22 -149 70 -30 51 -41 41 -18 -17 10 -28 21 -53 23 -56 14 -13 120 -33 177
-33 130 1 220 74 220 180 0 49 -22 99 -53 121 -12 9 -56 28 -97 44 -123 46
-164 115 -105 179 43 47 126 31 198 -38 20 -20 40 -36 43 -36 11 0 -4 68 -21
94 -13 20 -23 24 -43 20 -15 -3 -54 2 -87 10 -32 9 -62 16 -65 15 -3 -1 -24
-5 -48 -11z"/>
<path d="M5393 2453 c-13 -2 -23 -7 -23 -10 0 -3 10 -14 23 -25 21 -19 22 -29
28 -211 3 -106 10 -204 16 -218 17 -40 80 -86 136 -100 69 -16 182 -7 234 19
85 44 103 102 103 338 0 149 1 153 26 183 l26 31 -71 0 c-41 0 -71 -4 -71 -10
0 -5 4 -10 8 -10 4 0 15 -13 25 -29 14 -24 17 -56 17 -196 0 -187 -7 -214 -64
-253 -82 -56 -198 -29 -243 57 -21 40 -23 57 -23 194 0 116 4 156 16 183 9 19
23 34 30 34 22 0 16 17 -8 24 -25 6 -150 6 -185 -1z"/>
<path d="M6098 2455 c-3 -4 5 -18 19 -33 l25 -27 -4 -210 c-4 -164 -8 -218
-21 -247 -9 -21 -21 -38 -28 -38 -7 0 -10 -3 -6 -6 3 -4 93 -6 199 -5 162 2
199 5 228 20 40 20 80 88 80 136 0 31 -33 95 -48 95 -17 0 -62 49 -62 68 0 10
13 39 30 63 43 63 38 131 -12 162 -27 18 -148 32 -148 18 0 -5 8 -12 18 -15
68 -22 70 -210 3 -246 -52 -27 -101 -4 -115 54 -12 48 4 166 25 187 19 19 17
19 -65 19 -46 0 -91 2 -98 5 -8 3 -17 3 -20 0z m293 -295 c37 -20 55 -82 50
-173 -3 -73 -105 -114 -155 -64 -41 42 -47 186 -8 230 17 19 82 23 113 7z"/>
<path d="M6340 2043 c0 -29 5 -53 10 -53 13 0 13 70 0 90 -7 11 -10 0 -10 -37z"/>
<path d="M5060 2340 c0 -9 40 -30 56 -30 14 0 0 19 -21 28 -32 15 -35 15 -35
2z"/>
<path d="M5320 2321 c0 -20 -9 -37 -31 -55 l-30 -26 30 -37 c17 -21 31 -43 31
-50 0 -6 5 -15 10 -18 6 -4 10 33 10 104 0 67 -4 111 -10 111 -5 0 -10 -13
-10 -29z"/>
<path d="M4912 2038 c6 -20 76 -51 96 -43 13 5 4 13 -37 31 -62 28 -64 28 -59
12z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
